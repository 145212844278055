
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.developerPromotionsWrapper {
  --wrapper-background: var(--snow);
}

.developerPromotionsContainer {
  @include mobile {
    padding: 0;
  }
}

.heading {
  font-size: var(--font-size-xl);
  margin-bottom: 24px;

  @include mobile {
    margin-inline: 20px;
  }
}

.slide {
  width: auto;
}
