
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.subcategoryRadioSelectorBox {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
