
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.propertyGroupBox {
  @include desktop {
    width: 380px;
  }

  @include mobile {
    margin-bottom: 30px;
  }

  .propertyHeader {
    height: 80px;
    border-radius: 12px;
    background: #f5fafe;
    padding: 20px 24px;

    img {
      margin-left: 14px;
      vertical-align: middle;
    }

    .title {
      display: inline;
      font-size: var(--font-size-m);
      font-weight: var(--font-weight-medium);
      vertical-align: middle;
    }
  }

  .list {
    margin-top: 20px;
  }
}
