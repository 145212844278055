
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.propertyItem {
  font-size: var(--font-size-s);
  padding-block: 8px;

  &.blue {
    color: #0495ff;
    font-weight: var(--font-weight-medium);
  }
}
