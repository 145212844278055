
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
}

.properties {
  margin-top: 24px;

  @include desktop {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 40px;
  }
}
