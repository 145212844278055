
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.developerItem {
  border-radius: 12px;
  border: 1px solid rgb(var(--isabelline));
  overflow: hidden;

  @include desktop {
    width: 216px;
    height: 100px;
  }

  @include mobile {
    width: 145px;
    height: 80px;
  }

  img {
    padding: 20px;
    background-color: rgb(var(--white));
    object-fit: contain;
  }
}
