
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.radioSelectorBox {
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-s);
  color: rgb(var(--white));

  @include mobile {
    margin: 0 10px;
  }

  @include desktop {
    margin: 0 15px;
  }

  .radioSelector + .radioLabel {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    border-bottom: 2px solid transparent;
  }

  .radioSelector:checked + .radioLabel,
  .radioLabel:hover {
    border-color: rgb(var(--white));
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
  }

  .radioLabel {
    padding: 2px 10px;
  }

  .radioSelector {
    display: none;
  }
}
