
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.skeletonImage {
  width: 100%;
  height: 100%;
  filter: blur(8px);
  background-repeat: no-repeat;
}

.defaultSkeletonStyle {
  background-size: cover;
  background-position: center;
}

.skeletonBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.imageBox {
  position: relative;
}

.backgroundSkeleton {
  background-color: rgb(var(--gainsboro));
}

.imageLoading {
  opacity: 0;
}

.image {
  inset: 0;
  position: absolute;
}

.imageLoaded {
  opacity: 1;
  transition: opacity 150ms ease-in;
}
