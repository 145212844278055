
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.heading {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
}

.developersList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;

  @include desktop {
    gap: 30px;
  }

  @include mobile {
    overflow: auto;
    height: 190px;
    flex-direction: column;
    gap: 16px;
  }
}
