
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.wrapper {
  width: 100%;
  background-color: rgb(var(--wrapper-background, var(--white)));
  padding: 60px 0;

  @include mobile {
    padding: 20px 0;
  }
}
