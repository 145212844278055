
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.heading {
  font-weight: var(--font-weight-regular);

  @include desktop {
    font-size: var(--font-size-xl);
    margin-bottom: 25px;
  }

  @include mobile {
    font-size: var(--font-size-l);
    margin-bottom: 20px;
  }
}

.projects {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 40px;
  gap: 42px 30px;
}
