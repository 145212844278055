
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.developerPromotionItemBox {
  width: 216px;

  .imageBox {
    background-color: rgb(var(--white));
    border: 1px solid rgb(var(--gainsboro));
    border-radius: 12px;
    margin-bottom: 14px;
    height: 176px;
    text-align: center;
    padding-block: 46px;

    img {
      object-fit: contain;
    }
  }

  .detailsBox {
    text-align: center;

    .name {
      @include trim();
      display: block;
      padding-block: 8px;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-m);
    }

    .descriptionBox {
      width: 210px;
      height: 76px;
      display: grid;
      place-items: center;

      .description {
        @include trimOnLines(3);
        font-size: var(--font-size-m);
        line-height: 1.4;
      }
    }

    .allDetails {
      display: inline-block;
      border-radius: 30px;
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-medium);
      color: rgb(var(--azure));
      background-color: rgb(var(--white));
      border: 1px solid rgb(var(--azure));
      padding: 10px 18px;
      margin-top: 20px;
    }
  }
}
